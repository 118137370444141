import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Generic.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Privacy Policy`}</h1>
    <p>{`In accordance with the Data Protection Act 1998 we will never make your personal data available to any company for marketing purposes. However, our affiliated suppliers may hold your records so that your details and/or order can be processed. We use secure server technology with which to process your order. Cookies are small pieces of information which are stored by your browser on your computer’s hard drive and, whilst it is not currently our company policy to utilize cookies, we maintain the right to use them without prior notification if we deem it necessary and, if so, they will never store any of your personal details relating to yourself or your credit cards.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      